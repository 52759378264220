export default {
  methods: {
    setMeta (meta, value) {
      const metaDom = document.querySelector(`meta[name="${meta}"]`)
      if (metaDom) {
        metaDom.setAttribute('content', value)
      } else {
        const metaDom = document.createElement('meta')
        metaDom.setAttribute('name', meta)
        metaDom.setAttribute('content', value)
        document.head.appendChild(metaDom)
      }
    },
    setMetaProperty (meta, value) {
      const metaDom = document.querySelector(`meta[property="${meta}"]`)
      if (metaDom) {
        metaDom.setAttribute('content', value)
      } else {
        const metaDom = document.createElement('meta')
        metaDom.setAttribute('property', meta)
        metaDom.setAttribute('content', value)
        document.head.appendChild(metaDom)
      }
    },
    deleteMeta (meta) {
      const metaDom = document.querySelector(`meta[name="${meta}"]`)
      if (metaDom) {
        document.head.removeChild(metaDom)
      }
    }
  }
}
