<template>
  <div class="share-social" v-if="getSocialNetworks.length">
    <a v-for="(social, key) in getSocialNetworks" :href="social.link" :key="`social${key}`">
      <span class="icon" :style="`background: url('/assets/icons/${social.icon}') center / contain no-repeat`"></span>
    </a>
    <p v-if="view_text">{{$store.getters.GET_LANG ? 'Вы можете поделиться новостью в соцсетях' :
      'You can share the news in social networks'}}</p>
  </div>
</template>

<script>

export default {
  name: 'ShareSocial',

  props: {
    view_text: {
      type: Boolean,
      default: true
    },
    links: {
      type: Array,
      required: false,
      default: () => []
    },
    content: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  watch: {
    content: {
      immediate: true,
      handler () {
        this.setMetaTags()
      }
    }
  },

  methods: {
    addMetaTag (key, value) {
      const existingTag = document.querySelector(`meta[name='${key}']`)
      if (existingTag) {
        existingTag.parentNode.removeChild(existingTag)
      }

      const tag = document.createElement('meta')
      tag.setAttribute('name', key)
      tag.setAttribute('content', value)
      document.head.appendChild(tag)
    },

    setMetaTags () {
      if ('title' in this.content) {
        this.addMetaTag('og:title', this.content.title)
      }
      if ('description' in this.content) {
        this.addMetaTag('og:description', this.content.description)
      }
      if ('preview_photo' in this.content) {
        this.addMetaTag('og:image', this.content.preview_photo)
      }

      this.addMetaTag('og:url', window.location.href)
    }
  },

  computed: {
    getSocialNetworks () {
      const newsData = {
        title: this.content.title ? this.content.title : '',
        description: this.content.description ? this.content.description : '',
        image: this.content.preview_photo ? this.content.preview_photo : '',
        url: window.location.href
      }

      return [
        /* {
          name: 'facebook',
          link: `https://www.facebook.com/sharer.php?src=sp&u=${encodeURIComponent(newsData.url)}&title=${newsData.title}&description=${newsData.description}`,
          icon: 'facebook.svg'
        }, */
        {
          name: 'vk',
          link: `https://vk.com/share.php?url=${encodeURIComponent(newsData.url)}&title=${newsData.title}&description=${newsData.description}&image=${newsData.image}`,
          icon: 'vk.svg'
        },
        /* {
          name: 'twitter',
          link: `https://twitter.com/intent/tweet?text=${encodeURIComponent(newsData.title)}&url${newsData.url}`,
          icon: 'twitter.svg'
        }, */
        {
          name: 'telegram',
          link: `https://t.me/share/url?url=${encodeURIComponent(newsData.url)}&text=${newsData.title}`,
          icon: 'telegram.svg'
        }
      ]
    }
  }

}
</script>

<style scoped lang="scss">
  .share-social {
    display: flex;
    align-items: center;
    padding-top: 2rem;
    border-top: 1px solid #D5D6D9;

    a {
      width: 2rem;
      height: 2rem;
      background: #EEF4FA;
      border-radius: 100%;
      position: relative;

      .icon {
        position: absolute;
        left: 22%;
        top: 22%;
        width: 54%;
        height: 54%;
      }
    }
    a:not(:last-of-type){
      margin-right: 0.9375rem;
    }
    p {
      font-weight: 600;
      font-size: 0.8125rem;
      line-height: 154%;
      padding-left: 1.93rem;

      color: #5E686C;
    }
  }
  @media screen and (max-width: 420px){
    .share-social {
      flex-wrap: wrap;

      p {
        padding-left: 0;
        margin-top: 0.75rem;
      }
    }

  }
</style>
